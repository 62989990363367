export var ALL_APPIDS = new Set([
  730, 570, 1172470, 1326470, 1599340, 578080, 271590, 1085660, 252490, 1938090,
  1203220, 236390, 440, 1904540, 304930, 289070, 230410, 346110, 1245620,
  359550, 1782210, 1811260, 892970, 221100, 105600, 108600, 431960, 381210,
  394360, 413150, 227300, 1623660, 1174180, 251570, 218620, 252950, 489830,
  1222670, 668580, 813780, 438100, 1142710, 550, 255710, 4000, 960090, 1238810,
  261550, 1449850, 238960, 292030, 294100, 1063730, 250900, 242760, 236850,
  1919590, 1248130, 306130, 377160, 386360, 582010, 239140, 548430, 1568590,
  954850, 281990, 427520, 39210, 8930, 322330, 582660, 291550, 594650, 1091500,
  107410, 284160, 1238840, 444090, 629520, 397540, 1446780, 1158310, 1973530,
  440900, 814380, 1172620, 386180, 244210, 1677280, 275850, 526870, 435150,
  1506830, 393380, 322170, 1454400, 646570, 444200, 1466860, 1151340, 648800,
  10, 1189490, 766570, 632360, 1997040, 1971650, 1794680, 1418630, 1938010,
  1942280, 761890, 1569040, 945360, 779340, 1325860, 1293830, 1973710, 552990,
  513710, 1118200, 1366540, 231430, 1250410, 1426210, 1805480, 233860, 270880,
  365670, 457140, 1361210, 1517290, 3590, 1407200, 1476970, 1217060, 359320,
  700330, 268500, 1258080, 1267910, 1145360, 244850, 1905180, 1366800, 379430,
  962130, 367520, 787860, 1551360, 529340, 1283970, 214950, 534380, 374320,
  1263850, 812140, 48700, 714010, 627690, 376210, 1325200, 262060, 221380,
  933110, 976730, 311210, 389730, 686810, 1687950, 8500, 368260, 805550, 286160,
  1399720, 1237970, 219990, 1818450, 220200, 1665460, 304390, 881100, 872410,
  356190, 1144200, 975370, 1286830, 602960, 1401590, 1644960, 1184370, 264710,
  1086940, 1343400, 594570, 518790, 588650, 1147560, 1284210, 644930, 431730,
  786520, 1351080, 552500, 1147690, 924970, 1097150, 1787090, 1549250, 327070,
  703080, 49520, 815370, 240, 1549180, 438040, 1336490, 1009850, 1151640,
  877280, 72850, 774171, 1384160, 1328670, 601510, 1465360, 392160, 1599600,
  1240440, 380600, 216150, 1621690, 1016950, 1963370, 4700, 1097430, 363970,
  581320, 208650, 880940, 365360, 905370, 976310, 203770, 219740, 471710,
  383120, 232090, 1066780, 1535560, 346900, 387990, 325610, 758190, 1056640,
  728880, 1256670, 1593500, 1313140, 291480, 361420, 977400, 1041320, 305620,
  235460, 397900, 508440, 1817070, 1737340, 824270, 1296830, 1044720, 1062520,
  34330, 400040, 1100600, 868270, 244450, 1167630, 1419170, 310950, 329110,
  504230, 1128810, 1149460, 1677740, 916440, 1062090, 601150, 1976440, 1222680,
  466560, 10500, 22380, 1891700, 335240, 635260, 872790, 573090, 663090, 287700,
  1201540, 1404210, 570940, 268910, 1659040, 453480, 1961460, 227940, 1604030,
  1797880, 312520, 1286680, 228200, 2064650, 1468810, 266840, 297000, 985810,
  767560, 335300, 433340, 872200, 784080, 883710, 707010, 12210, 1461810,
  1113000, 466240, 848450, 323190, 1290000, 1201240, 505460, 551170, 1527950,
  1172380, 1468260, 211820, 218230, 782330, 637090, 699130, 613100, 961200,
  1046930, 568220, 47890, 382310, 1255630, 1029690, 620, 1824220, 1714040,
  495420, 225540, 459820, 304050, 1588010, 1092790, 389430, 1675900, 1206560,
  1190340, 454650, 527230, 571740, 1546320, 1096530, 200210, 252530, 373420,
  1693980, 333420, 544810, 1013320, 1977530, 436150, 1029780, 203160, 493340,
  223750, 1877960, 1030840, 552520, 1708520, 1817230, 431240, 1451190, 32470,
  744900, 1284190, 638970, 477160, 1850570, 1332010, 838350, 400750, 636480,
  544730, 1447430, 1692250, 460930, 8800, 466170, 1173510, 1377580, 834910,
  1222140, 1530160, 365960, 391220, 920210, 1069660, 233450, 885970, 843380,
  254700, 1180380, 704270, 1022450, 412020, 1190000, 253710, 858820, 629760,
  899770, 1343370, 1102190, 1129580, 1625450, 40970, 1170950, 967250, 1196590,
  312660, 1827180, 311690, 9900, 469600, 1048370, 1335200, 34030, 1211020,
  750920, 632470, 1284410, 251060, 269950, 1649240, 3910, 719890, 1718570,
  640820, 851850, 1943950, 360430, 331470, 1690710, 2066020, 588430, 702320,
  269210, 268850, 212680, 673950, 1068820, 1237950, 1235140, 1238860, 1927780,
  1353300, 200510, 109600, 314160, 690830, 1593350, 1017900, 1244460, 1017180,
  464920, 665300, 655500, 1377380, 780290, 200260, 1313860, 552100, 1397920,
  282070, 690790, 24010, 784950, 919640, 736590, 1222690, 1531430, 324800,
]);
